import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';//OnChanges
import { DatosGeneralesService } from '@servicios/datosGenerales.service';

@Component({
  selector: 'app-campos-json',
  templateUrl: './campos-json.component.html',
  styleUrls: ['./campos-json.component.css']
})
export class CamposJsonComponent implements OnInit {// OnChanges
  @Input() campo: any;
  @Input() pos: any;
  @Input() tipo: string;
  @Input() tipoda: string;
  @Input() opcionesCampos: any;
  @Input() configuracionRecarga;
  @Input() deshabilitado: boolean;
  @Input() nombre: string;
  @Input() idReg: string;
  @Input() idTabla: string;
  @Input() recarga: string;
  @Input() bloquea: string;
  @Input() unico: string;
  @Input() maximo: number;
  @Output() enviarEjecucionFunciones = new EventEmitter();
  @Output() enviarPeticionBloqueo = new EventEmitter();
  public tituloCheck: string;
  constructor(private _servicioDatosGenerales: DatosGeneralesService) { }

  ngOnInit() {
    this.ponerOpcionesCampo();
    if (this.tipo == 'T') {
      if ((this.tipoda == 'D' || this.tipoda == 'M') && !this.campo.valor) {
        this.campo.valor = 0; // Se pone en cero ya que la librería de decimales lo pone en el campo pero no en el ngmodel
      }
    } else if (this.tipo == 'S' && this.campo.valor != '') {
      this.ponerValorSelect();
    } else if (this.tipo == 'C') {
      this.ponerValorCheck();
    }
    // this.iniciado = true;
    if (this.bloquea == 'S') { // Envía la petición de bloqueo para que al editar un registro en la tabla bloquee los campos que corresponda
      this.enviarPeticionBloqueo.emit({ bloquea: this.bloquea, campo: this.campo, posicion: this.pos, idReg: this.idReg, idTabla: this.idTabla });
    }
  }

  // ngOnChanges(){
  //     // if(this.iniciado){
  //     //     if(this.tipo == 'S' && this.campo.valor!=''){
  //     //         this.ponerValorSelect();
  //     //     }else if(this.tipo == 'C'){
  //     //         this.ponerValorCheck();
  //     //     }
  //     // }
  // }

  ponerOpcionesCampo() {
    let i = 0;
    while (i < this.opcionesCampos.length) {
      if (this.opcionesCampos[i].columna == this.pos) {
        if (this.opcionesCampos[i].opcionesSelect) {
          this.campo.opciones = this.opcionesCampos[i].opcionesSelect;
        } else if (this.opcionesCampos[i].opcionesCheck) {
          this.campo.opciones = this.opcionesCampos[i].opcionesCheck;
        }
        i = this.opcionesCampos.length;
      }
      i++;
    }
  }

  ponerValorSelect() {
    if (this.campo.opciones) {
      if (this.campo.opciones[0] && this.campo.opciones[0].id != '' && this.tipoda == 'T') {
        this.campo.opciones.splice(0, 0, { 'id': '', 'name': 'Seleccione' });
      } else if (this.campo.opciones && this.campo.opciones[0] && this.campo.opciones[0].id != '0' && this.tipoda == 'N') {
        this.campo.opciones.splice(0, 0, { 'id': '0', 'name': 'Seleccione' });
      }
      for (let i = 0; i < this.campo.opciones.length; i++) {
        if (this.campo.opciones[i].id == this.campo.valor) {
          this.campo.contenido = this.campo.opciones[i];
        }
      }
    } else {
      this.campo.contenido = [];
    }
  }

  ponerValorCheck() {
    if (this.campo.valor == this.campo.opciones.true.valor) {
      this.campo.contenido = true;
      this.tituloCheck = this.campo.opciones.true.titulo;
    } else {
      this.campo.contenido = false;
      this.campo.valor = this.campo.opciones.false.valor;
      this.tituloCheck = this.campo.opciones.false.titulo;
    }
  }

  ejecutarFuncionesCampo(tipo: string) {
    if (tipo == 'S') {//Select
      this.campo.valor = this.campo.contenido.id;
    } else if (tipo == 'C') {//Check
      if (this.campo.contenido) {
        this.campo.valor = this.campo.opciones.true.valor;
        this.tituloCheck = this.campo.opciones.true.titulo;
      } else {
        this.campo.valor = this.campo.opciones.false.valor;
        this.tituloCheck = this.campo.opciones.false.titulo;
      }
    }
    this.enviarEjecucionFunciones.emit({ recarga: this.recarga, bloquea: this.bloquea, unico: this.unico, maximo: this.maximo, campo: this.campo, posicion: this.pos, idReg: this.idReg, idTabla: this.idTabla });
  }

  ponerValorFechaFormato(event: any) {
    let d = new Date(Date.parse(event));
    let year = d.getFullYear();
    let month = (d.getMonth() + 1).toString().padStart(2, '0');
    let day = d.getDate().toString().padStart(2, '0');
    this.campo.valor = `${year}-${month}-${day}`;
  }

  formatearHora() {
    if (this.campo.contenido && this.campo.contenido != '') {
      let hour = this.campo.contenido.getHours();
      let min = this.campo.contenido.getMinutes();
      this.campo.valor = `${hour}:${min}`;
    }
  }
}
