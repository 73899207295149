import { Component, ChangeDetectorRef, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { servicioPeticiones } from '@servicios/servicioPeticiones';
import { DatosGeneralesService } from '@servicios/datosGenerales.service';
import { Editor } from 'primeng/editor';

@Component({
  selector: 'app-campos-formulario',
  templateUrl: './campos-formulario.component.html',
  styleUrls: ['./campos-formulario.component.css']
})

export class CamposFormularioComponent implements OnInit {
  @Input() tabRelacionado: any;
  @Input() tabAbierto: any;
  @Input() campo: any;//campo a renderizar en el formulario
  @Input() idFormulario: string;
  @Input() form: FormGroup;
  @Input() accion: string;//Con la accion se define que campos se deben bloquear
  @Input() camposPadre: any;//Campos que vienen desde el padre para poder tomar los valores en los campos hijos
  @Output() enviarSenalFuncionesCampos = new EventEmitter();//Funcion de prueba para recarga de selects y campos
  @Output() enviarIdsSeleccionados = new EventEmitter();//Output que envia los ids seleccionados desde un hijo al campo que se encuentra oculto en el mismo nivel del tipo J
  @Output() enviarSenalRecargar = new EventEmitter();//Funcion que envía la señal para que se recarguen los datos del formulario completo
  @Output() ejecutarRecargaEnTabla = new EventEmitter();//Función que envía la señal de recarga a los compos que se pintan en la búsqueda avanzada en las tablas
  private abrirDialogo: boolean = false;//Campo que indica si se debe abrir un dilogo ya sea de dirección, vista previa, etre otros
  private datos: any[] = []; //Array que contiene los datos filtrados del autocomplete, imagenes cargadas del upload, copia de las opciones de la lista de seleccion vanzada
  private palabraBusqueda: string = '';//Palabra para realizar filtros de la lista de selección avanzada
  // public urlCarga:string;
  public tituloCampo: string = '';
  public caracteresExpr: RegExp;
  public pagina: number = 1;
  public idRegistroPaginador: number;
  public loading: boolean = false;
  @ViewChild('editorTexto', { static: false }) editor: Editor;//Para editor

  constructor(
    private _servicioPeticiones: servicioPeticiones,
    private _servicioDatosGenerales: DatosGeneralesService,
    private cd: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    if (this.campo.tipoObjeto == 'W' && this.accion == 'M' && !this.campo.bloqueado) {
      setTimeout(() => {
        if (this.campo.valor !== null) {
          this.editor.writeValue(this.campo.valor);
          this.editor.quill.setSelection(0, this.campo.valor.length, 1000);
        }
      }, 100);
    }
  }

  ngOnInit() {
    //Defino las longitudes del campo
    if (this.campo.longitud == '0' || this.campo.longitud == '0.0' || this.campo.longitud == 0) {
      if (this.campo.tipoObjeto == 'A' || this.campo.tipoObjeto == 'E') {//Area de texto
        this.campo.longitud = '2000';
      } else if (this.campo.tipoObjeto == 'T') {//Objetos tipo texto
        this.campo.longitud = '200';
      }
    }
    //Bloquea en los campos mediante una expresion regular a los que no permite caracteres especiales
    if (((this.campo.tipoObjeto == 'T' && this.campo.tipoDato == 'T') || this.campo.tipoObjeto == 'A' || this.campo.tipoObjeto == 'E') && this.campo.aceptaEspeciales == 'N') {
      this.caracteresExpr = /[\w\s]$/;//Valida que no ingrese caracteres especiales
    } else {
      this.caracteresExpr = /[^]/; //No le pongo condiciones para que no valide nada
    }

    if (this.campo.tipoObjeto == '1') {//Lista de seleccion avanzada
      this.loading = false;
      this.idRegistroPaginador = 0;
      if (!this.campo.referidos || this.campo.referidos.length <= 0) {
        this.datos = this.campo.opcionesListaAvanzada;
        this.definirValoresPaginaTabla();
      }
    }
    if (this.campo.tipoObjeto == 'D') { // Referencia compuesta directa
      this.cargarDatosReferenciaDirecta();
    }
    if ((this.accion == 'A' && this.campo.adicionar == 'N')) {
      this.campo.bloqueado = true;
    }
    if (this.campo.tipoObjeto == 'C' && this.accion == 'B') {
      this.llenarCampoCheckSelect();
    }
    this.ponerValorCampo();
    // Después de cargar los datos, forzamos la detección de cambios
    this.cd.detectChanges();
  }

  //Función para poner los valores a los selects, autocompletar y radio cuando se recarga la página y contiene un valor
  ponerValorCampo() {
    let opciones: any = [];
    if (this.campo.valor != '' && this.campo.valor != null) {
      if (this.campo.tipoObjeto == 'S' || this.campo.tipoObjeto == 'N') {//Selects y radio buttons || this.campo.tipoObjeto == 'R'
        opciones = this.campo.opciones;
      } else if (this.campo.tipoObjeto == 'I') {//Autocompletar
        opciones = this.campo.opcionesAutocompletar;
      } else if (this.campo.tipoObjeto == '1') {//Lista de seleccion avanzada
        this.campo.contenido = { id: this.campo.valor, texto: this.campo.contenido };
      } else if (this.campo.tipoObjeto == 'C') {//Checks
        if (this.campo.opcionesCheck != null && this.campo.opcionesCheck != '') {
          if (this.campo.valor == this.campo.opcionesCheck.true.valor) {
            this.campo.contenido = true;
            this.tituloCampo = this.campo.opcionesCheck.true.titulo;
          } else {
            this.campo.contenido = false;
            this.tituloCampo = this.campo.opcionesCheck.false.titulo;
          }
        }
      } else if (this.campo.tipoObjeto == 'O') {//Hora
        let datos = this.campo.valor.split(':');
        this.campo.contenido = new Date(null, null, null, datos[0], datos[1]);
      }
      if (opciones != null) {
        //Se pone el valor cuando ya se le asigno alguno
        for (let i = 0; i < opciones.length; i++) {
          if (opciones[i].id == this.campo.valor) {
            this.campo.contenido = opciones[i];
            i = opciones.length;
          }
        }
      }
      if (this.campo.tipoObjeto == 'P') {
        this.campo.contenido = this.campo.valor.split(";");
      }
      if (this.campo.tipoObjeto == 'L') {
        this.campo.contenido = this.campo.valor.split(",");
      }
    } else if (this.accion != 'B' && this.campo.defecto && this.campo.defecto != '') {
      if (this.campo.tipoObjeto != 'S' && this.campo.tipoObjeto != 'N' && this.campo.tipoObjeto != 'I' && this.campo.tipoObjeto != '1' && this.campo.tipoObjeto != 'C') { // this.campo.tipoObjeto != 'R' &&
        this.campo.valor = this.campo.defecto;
      } else {
        if (this.campo.tipoObjeto == 'S' || this.campo.tipoObjeto == 'N') {//Selects //y radio buttons || this.campo.tipoObjeto == 'R'
          opciones = this.campo.opciones;
        } else if (this.campo.tipoObjeto == 'I') {//Autocompletar
          opciones = this.campo.opcionesAutocompletar;
        } else if (this.campo.tipoObjeto == '1') {//Lista de seleccion avanzada//NO ES NECESARIO POR QUE EÑ CONTENIDO LO TRAE DESDE LA CONSULTA
          opciones = this.campo.defecto.split(',');
          this.campo.contenido = { id: opciones[0], texto: opciones[1] };
          this.campo.valor = opciones[0];
        } else if (this.campo.tipoObjeto == 'C') {//Checks
          if (this.campo.defecto == this.campo.opcionesCheck.true.valor) {
            this.campo.contenido = true;
            this.tituloCampo = this.campo.opcionesCheck.true.titulo;
            this.campo.valor = this.campo.opcionesCheck.true.valor;
          } else {
            this.campo.contenido = false;
            this.tituloCampo = this.campo.opcionesCheck.false.titulo;
            this.campo.valor = this.campo.opcionesCheck.false.valor;
          }
        }
        //Se pone el valor por defecto en los campos
        for (let i = 0; i < opciones.length; i++) {
          if (opciones[i].id == this.campo.defecto) {
            this.campo.contenido = opciones[i];
            i = opciones.length;
            this.campo.valor = this.campo.defecto;
          }
        }
      }
    } else {
      if (this.campo.tipoObjeto == 'C') {
        if (this.accion != 'B') {
          this.campo.contenido = false;
          if (this.campo.opcionesCheck != null && this.campo.opcionesCheck != '') {
            this.tituloCampo = this.campo.opcionesCheck.false.titulo;
            this.campo.valor = this.campo.opcionesCheck.false.valor;
          }
        } else {
          this.campo.contenido = { 'id': '', 'name': 'Seleccione' };
        }
      }
      if (this.campo.tipoObjeto == '1') {
        this.campo.contenido = { id: '', texto: '' };
      }
    }
  }

  //Función para filtrar desde un autocompletar
  filtrarValor(event: any) {
    let query = event.query;
    this.datos = this.filtrarValorLista(query, this.campo.opcionesAutocompletar)
  }

  //Función que retorna un arreglo con las opciones filtradas de un autocomplete
  filtrarValorLista(query, opciones: any[]): any[] {
    let filtered: any[] = [];
    for (let i = 0; i < opciones.length; i++) {
      let opcion = opciones[i];
      if (opcion.value.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(opcion);
      }
    }
    return filtered;
  }

  //Función que cierra la direccion sin enviar valores ya sea de una direccion estamdar o de la vista previa
  cerrarDialogo(event) {
    this.abrirDialogo = event;
  }

  //Función que toma el dato devuelto por el campo de direccion
  tomarDatoDireccion(event) {
    this.campo.valor = event;
    this.abrirDialogo = false;
  }

  verificarEjecucionBusqueda() {
    if (this.campo.referidos && this.campo.referidos.length > 0) {
      this.ejecutarBusquedaListaAvanzada(true);
    } else {
      this.abrirDialogo = true;
    }
  }

  //Función que ejecutará las búsquedas de la lista de seleccion avanzada
  ejecutarBusquedaListaAvanzada(inicial: boolean) {
    if (!inicial) {
      this.loading = true;
    }
    let codigoSesion = this._servicioDatosGenerales.getIdentity().codigoSesion;
    let llamado = { "accion": "busquedaListaAvanzada", parametros: { "codigoSesion": codigoSesion, "formulario": this.idFormulario, "campo": this.campo.nombre, "valor": this.palabraBusqueda, "pagina": this.pagina, "referidos": this.campo.referidos } };
    this._servicioPeticiones.enviarObjetoBus(llamado)
      .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
        response => { //si la peticion fue exitosa ejecuta la funcion response
          let respuesta = response[0].valores;
          if (respuesta.resultado == 0) {//Si No obtiene problemas al traer el menú
            this.campo.opcionesListaAvanzada = respuesta.datos;
            if (inicial) {
              this.datos = respuesta.datos;
              this.abrirDialogo = true;
            }
            this.definirValoresPaginaTabla();
          }
          this.loading = false;
        }
      )
  }

  //Consulta la tabla quitándole el filtro de palabra
  volverSinFiltroPalabra() {
    if (this.palabraBusqueda == '') {
      this.campo.opcionesListaAvanzada = this.datos;
      this.pagina = 1;
      this.idRegistroPaginador = 0;
      this.definirValoresPaginaTabla();
    }
  }

  limpiarFiltrosLista() {
    this.palabraBusqueda = '';
    this.campo.opcionesListaAvanzada = this.datos;
    this.pagina = 1;
    this.idRegistroPaginador = 0;
    this.definirValoresPaginaTabla();
  }

  cambiarPagina(valor: any) {
    this.idRegistroPaginador = valor.first;
    this.pagina = valor.page + 1;
    this.ejecutarBusquedaListaAvanzada(false);//true
  }

  //Función que toma los datos de la lista de seleccion avanzada
  ejecutarAcccionLista(opcion) {
    this.campo.contenido = opcion;
    this.campo.valor = this.campo.contenido.id;
    this.abrirDialogo = false;
    this.ejecutarFuncionesCampo();
  }

  definirValoresPaginaTabla() {
    let inicial: number;
    let final: number;
    let total: number;
    if (this.campo.opcionesListaAvanzada && this.campo.opcionesListaAvanzada.cantidadRegistros && this.campo.opcionesListaAvanzada.cantidadRegistros > 0) {
      total = this.campo.opcionesListaAvanzada.cantidadRegistros;
      inicial = ((this.pagina - 1) * 10) + 1;
      this.tituloCampo = 'Registros ' + inicial + ' a ';
      if (total <= 10) {
        final = total;
      } else {
        final = ((this.pagina - 1) * 10) + 10;
        if (final > total) {
          final = total;
        }
      }
      this.tituloCampo += final + ' de ' + this.campo.opcionesListaAvanzada.cantidadRegistros;
    } else {
      this.tituloCampo = 'No se encuentran registros';
    }
  }

  //Función que asigna los valores de campos con información json
  asignarValorCampo() {
    if (this.campo.tipoObjeto == 'S' || this.campo.tipoObjeto == 'N' || this.campo.tipoObjeto == 'I') {// this.campo.tipoObjeto == 'R' ||
      this.campo.valor = this.campo.contenido.id;
    } else if (this.campo.tipoObjeto == 'C') {
      if (this.accion == 'A' || this.accion == 'M') {
        if (this.campo.contenido) {
          this.campo.valor = this.campo.opcionesCheck.true.valor;
          this.tituloCampo = this.campo.opcionesCheck.true.titulo;
        } else {
          this.campo.valor = this.campo.opcionesCheck.false.valor;
          this.tituloCampo = this.campo.opcionesCheck.false.titulo;
        }
      } else {//En busqueda es un select en vez de un check
        this.campo.valor = this.campo.contenido.id;
      }
    } else if (this.campo.tipoObjeto == 'P' || this.campo.tipoObjeto == 'L') {
      this.campo.valor = this.campo.contenido.join();
      this.ejecutarFuncionesCampo();
    }
  }

  //Función que identifica las funciones que contiene un campo y las ejecuta(ej: Recarga de objetos, funcir, etc)
  ejecutarFuncionesCampo() {
    if (this.accion != 'B') {
      if (((this.campo.tipoObjeto == 'T' && this.campo.tipoDato == 'T') || this.campo.tipoObjeto == 'A' || this.campo.tipoObjeto == 'E') && this.campo.aceptaMinusculas == 'N') {
        this.campo.valor = this.campo.valor.toUpperCase();
      }

      if (this.campo.tipoDato == 'D' || this.campo.tipoDato == 'M') {
        if (this.campo.valor == null || this.campo.valor == '') {
          this.campo.valor = 0;
        }
      }
      this.enviarSenalFuncionesCampos.emit(this.campo);//No se verifica desde este componente si recarga campo ya que se debe validar también que sea referido
    } else {
      this.ejecutarRecargaEnTabla.emit(this.campo);
    }
  }

  //Método que tocó hacer ya que se encontro en un formulario (ejm: tickets) que cuando se escribe en un editor de texto
  //e inmediatamente se da clic en un dhtm tipo botón, no se alcanza a enviar la información del editor ya se acomo raeferida o siemplemente valor
  //Cosa que no pasa al dar click en un <a>, en un campo o incluso dando click por fuera y después en el botón (Emn este caso si toma el valor del editor).
  tomarValorEditor() {
    setTimeout(() => { // Se debe hacer el timeout ya que si no se hace, no captura ell último caracter ingresado
      this.ejecutarFuncionesCampo();
    }, 0);
  }

  //Funcion que toma los valores seleccionados de una tabla con checks y los manda un nivel arriba para asociarlos
  tomarValoresCheckHijo(ids: any) {
    this.enviarIdsSeleccionados.emit(ids);
  }

  actualizarRegistrosCampo(datosNuevosRegistros: any) {
    this.campo.valoresEstructura = datosNuevosRegistros.datosRegistros;
  }

  verificarEstadoAyuda() {
    return this._servicioDatosGenerales.getAyuda();
  }

  llenarCampoCheckSelect() {
    this.campo.opciones = [];
    this.campo.opciones.push({ 'id': '', 'name': 'Seleccione' });

    if (this.campo.opcionesCheck?.true) {
      this.campo.opciones.push({ 'id': this.campo.opcionesCheck.true.valor, 'name': this.campo.opcionesCheck.true.titulo });
    }

    if (this.campo.opcionesCheck?.false) {
      this.campo.opciones.push({ 'id': this.campo.opcionesCheck.false.valor, 'name': this.campo.opcionesCheck.false.titulo });
    }
  }

  tomarValorListaJson(datos: any) {
    if (datos == '-1') {//Quiere decir que está en modo de edicion
      this.campo.contenido = '-1';
    } else if (datos == '') {//Cancelo la edición
      this.campo.contenido = '';
    } else {//Confirmó la edición
      this.campo.valor = datos;
      this.campo.contenido = '';
      this.ejecutarFuncionesCampo();
    }
  }

  enviarSenalRecargaPadre(event: any) {
    this.enviarSenalRecargar.emit(event);
  }

  formatearHora() {
    if (this.campo.contenido != null && this.campo.contenido != '') {
      let hour = this.campo.contenido.getHours();
      let min = this.campo.contenido.getMinutes();
      this.campo.valor = `${hour}:${min}`;
    }
  }

  //Función que se crea para dhtms de referencia directa para que carguel a información de la tabla
  cargarDatosReferenciaDirecta() {
    this.loading = true;
    let llamado = { "accion": "busqueda", parametros: { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "formulario": this.campo.estructuraFormulario.id } };
    this._servicioPeticiones.enviarObjetoBus(llamado)
      .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
        response => { //si la peticion fue exitosa ejecuta la funcion response
          let respuesta = response[0].valores;
          if (respuesta) {
            if (respuesta.resultado == '0') {//Si No obtiene problemas al traer el menú
              this.campo.valoresEstructura = respuesta.tablaResultados;
              this.loading = false;
            } else {
              this._servicioDatosGenerales.enviarMensaje('E', respuesta['mensaje']);
              this.loading = false;
            }
          } else {
            this.loading = false;
            this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
          }
        },
        error => {
          this.loading = false;
          this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
        }
      )
  }
}
