import { Component, OnInit, HostListener } from '@angular/core';
import { servicioPeticiones } from '@servicios/servicioPeticiones';
import { DatosGeneralesService } from '@servicios/datosGenerales.service';
import { Router } from '@angular/router';
import { Tab } from '@modelos/Tab';
import { Message } from 'primeng/api'; import { MenuItem } from 'primeng/api';
import { funcionesSesion } from '@funcionesGenerales/funcionesSesion';
import { UserIdleService } from 'angular-user-idle';
import { MenuNotificaciones } from '@modelos/menuNotificaciones';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  public identity: any;
  public listaTabs: Array<any> = [];
  public tab: Tab;
  public modoTutorial: boolean;
  public nombreUsuario: string;
  public mensaje: string;
  public indice: number = 0;//Me indica que tab es el actual
  public ultimoIndice: number = 0;//Indica el último indice ingresado
  public msgs: Message[] = [];
  public bloqueado: boolean = false;
  public idIntervaloNotificaciones: any = null;
  public ayudaActiva: boolean;
  public items: MenuItem[];
  public menuNotificaciones: Array<MenuNotificaciones> = [];
  public idsNotificaciones: Array<number>;
  public cantidadNotificaciones: number = 0;
  public abrirDialogoCerrarSesion: boolean = false;
  public loading: boolean = false;
  public imagen: string;
  public codigoAcceso: string;
  public idAcceso: string;
  public scriptChatBot: any;
  public version = environment.version;
  public anioActual = new Date().getFullYear();

  constructor(private _servicioPeticiones: servicioPeticiones, public _servicioDatosGenerales: DatosGeneralesService, private _router: Router, private _funcionesSesion: funcionesSesion, private userIdle: UserIdleService) { }

  ngOnInit() {
    this.identity = this._servicioDatosGenerales.getIdentity();
    this.ayudaActiva = this._servicioDatosGenerales.getAyuda();
    if (!this.identity) {//Si no esta loggeado se devuelve al inicio
      this._router.navigate(['/login']);
    } else {
      this.verificarEstadoSesion();//Para que no espere los 10 minutos para verificar el estado de la sesion
      // Se inicializa la configuración que verifica la inactividad del usuario en el sistema
      let tiempoInactividad: number = this._servicioDatosGenerales.getIdentity().configuracion.tiempoInactividad * 60;
      this.userIdle.setConfigValues({ idle: tiempoInactividad, timeout: 1, ping: 1 });
      this.userIdle.onTimerStart().subscribe(() => {
        var eventList = ["click", "mouseover", "keydown", "DOMMouseScroll", "mousewheel", "mousedownf", "touchstart", "touchmove", "scroll", "keyup"];
        for (let event of eventList) {
          document.body.addEventListener(event, () => this.userIdle.resetTimer());
        }
      });
      // Start watch when time is up.
      this.userIdle.onTimeout().subscribe(() => {
        console.log('Inactivando sesion');
        this.inactivarSesion();
      })

      this.nombreUsuario = this.identity.usuario.nombreUsuario;
      if (this.identity.usuario.imagen && this.identity.usuario.imagen != 'Pendiente' && this.identity.usuario.imagen != 'pendiente') {
        this.imagen = this._servicioDatosGenerales.getUrlDescarga() + this.identity.usuario.imagen;

        //validar imagen que no sea 404
        let img = new Image();
        img.src = this.imagen;
        img.onload = () => {
          this.imagen = this.imagen;
        };
        img.onerror = (e) => {
          this.imagen = 'assets/images/profile.png';
        };

      } else {
        this.imagen = 'assets/images/profile.png';
      }

      if (this.identity.mensaje) {
        this.mostrarMensaje('A', this.identity.mensaje);
      }
      this.menuNotificaciones.push(new MenuNotificaciones());
      this.idsNotificaciones = [];
    }
    this.listaTabs.push(new Tab({ id: '0', 'titulo': 'Dashboard' }))
    this.indice = 0;
    this.llenarItemsIniciales();
  }

  ngAfterViewInit() {
    // this.scriptChatBot = document.createElement("div");
    // document.getElementById("contenedorchatbot").appendChild(this.scriptChatBot);
    // this.scriptChatBot.innerHTML = `<df-messenger
    //                                     intent="WELCOME"
    //                                     chat-title="Juan"
    //                                     agent-id="2f5208d8-49ea-4546-b76d-28ffac9e79cc"
    //                                     language-code="es">
    //                                 </df-messenger>`;
  }


  ngOnDestroy() {
    // this.scriptChatBot.parentNode.removeChild( this.scriptChatBot );
  }

  //Evento para abrir diálogo para confirmar cerrado se sesión
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    if (environment.production) {
      event.returnValue = true;
    }
  }

  @HostListener('window:unload')
  unloadHandler() {
    if (environment.production) {
      this.cerrarSesion('-2');
    }
  }


  llenarItemsIniciales() {
    this.items = [
      { label: '', icon: 'fa fa-commenting-o', command: (event) => { this.cambiarEstadoAyuda(!this.ayudaActiva) } },
      { label: 'Inactivar sesión', icon: 'fa fa-ban', command: (event) => { this.inactivarSesion(); } },
      { label: 'Cerrar sesión', icon: 'fa fa-power-off', command: (event) => { this.abrirDialogoCerrarSesion = true; } }
    ];
    if (this.ayudaActiva) {
      this.items[0].label = 'Desactivar ayudas visuales';
    } else {
      this.items[0].label = 'Activar ayudas visuales';
    }
  }

  consultarNotificacionesUsuario() {
    if (this._servicioDatosGenerales.getIdentity()) {
      let llamado = { "accion": "obtenerAlertas", parametros: { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion } };
      this._servicioPeticiones.enviarObjetoBus(llamado)
        .subscribe(//suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
          response => { //si la peticion fue exitosa ejecuta la funcion response
            let respuesta = response[0].valores;//Obtengo la respuesta del api rest
            if (respuesta.resultado == 0) {
              this.cantidadNotificaciones = respuesta.cantidadAlertas;
              this.menuNotificaciones = [];
              this.idsNotificaciones = [];
              this.menuNotificaciones.push(new MenuNotificaciones());
              if (this.cantidadNotificaciones > 0) {
                this.llenarItemsNotificaciones(respuesta.alertas);
              }
            }
          },
          error => {
            console.log("No se puede tener conexión para obtener las alertas");
          }
        )
    }
  }

  llenarItemsNotificaciones(alertas: Array<any>) {
    this.menuNotificaciones[0].label = this.cantidadNotificaciones + ' Notificaciones';
    let icono: string;
    for (let i = 0; i < alertas.length; i++) {
      switch (alertas[i].tipo) {
        case 1: { icono = 'fa-exclamation-triangle fondo_rojo_claro'; break; } // error
        case 2: { icono = 'fa-exclamation-circle fondo_amarillo'; break; } // Advertencia
        case 3: { icono = 'fa-info-circle fondo_azul_claro'; break; } // Informativa
        case 4: { icono = 'fa-check-circle fondo_verde'; break; } // Finalizado
      }; this.menuNotificaciones[0].items.push({ separator: true });
      this.menuNotificaciones[0].items.push({ label: alertas[i].mensajeAlerta, icon: 'icono_notificaciones estado fa ' + icono, command: (event) => { } });
      this.idsNotificaciones.push(alertas[i].idAlerta);
    }
  }

  marcarNotificacionesLeidas() {
    if (this.menuNotificaciones[0].items && this.menuNotificaciones[0].items.length > 0) {
      let llamado = { "accion": "marcarAlertas", parametros: { "alertas": this.idsNotificaciones, "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion } };
      this._servicioPeticiones.enviarObjetoBus(llamado)
        .subscribe(//suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
          response => { //si la peticion fue exitosa ejecuta la funcion response
            let respuesta = response[0].valores;//Obtengo la respuesta del api rest
            if (respuesta.resultado == 0) {
              this.cantidadNotificaciones = 0;
              this.menuNotificaciones = [];
              this.idsNotificaciones = [];
              this.menuNotificaciones.push(new MenuNotificaciones());
            }
          },
          error => {
            console.log("No se pueden marcar las alertas");
          }
        )
    }
  }

  //Función que inactiva la sesión del usuarioactualizando el estado de dicha sesion
  inactivarSesion() {
    let llamado = { "accion": "inactivar", parametros: { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion } };
    this._servicioPeticiones.enviarObjetoBus(llamado)
      .subscribe(//suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
        response => { //si la peticion fue exitosa ejecuta la funcion response
          let respuesta = response[0].valores;//Obtengo la respuesta del api rest
          if (respuesta != null) {
            this._servicioDatosGenerales.limpiarMensajes();
            if (respuesta['resultado'] == '0') {
              this._servicioDatosGenerales.setFormularioBloqueado(this.bloqueado);
              this.userIdle.stopWatching();
              if (this.idIntervaloNotificaciones) {
                clearInterval(this.idIntervaloNotificaciones);
                this.idIntervaloNotificaciones = null;
              }
              this.bloqueado = true;
            } else {
              this.mostrarMensaje('E', respuesta['mensaje']);
            }
          } else {
            this.mostrarMensaje('E', 'Ocurrió un error inesperado, por favor contáctese con el administrador del sistema');
          }
        },
        error => {
          this.mostrarMensaje('E', 'No se puede tener conexión, por favor recargue el sistema, si el error persiste contáctese con el administrador.');
        }
      )
  }

  //Función que verifica en que estado se encuentra la sesión del usuaio
  verificarEstadoSesion() {
    let llamado = { "accion": "confirmar", "parametros": { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion } };
    this._servicioPeticiones.enviarObjetoBus(llamado)
      .subscribe(//suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
        response => { //si la peticion fue exitosa ejecuta la funcion response
          let respuesta = response[0].valores;
          if (respuesta && respuesta['resultado'] == 0) {
            let estado = respuesta['estado'];
            if (estado === 'Activa') {
              this.bloqueado = false;
              this.userIdle.startWatching();//Inicio el proceso que me verifica la actividad de la sesión
              if (!this.idIntervaloNotificaciones) {
                this.consultarNotificacionesUsuario();
                this.idIntervaloNotificaciones = setInterval(() => { this.consultarNotificacionesUsuario(); }, 300000); // 5 Minutos
              }
            } else if (estado === 'Inactiva') {
              this.bloqueado = true;
              this.userIdle.stopWatching();
              if (this.idIntervaloNotificaciones) {
                clearInterval(this.idIntervaloNotificaciones);
                this.idIntervaloNotificaciones = null;
              }
            } else {
              this.userIdle.stopWatching();
              if (this.idIntervaloNotificaciones) {
                clearInterval(this.idIntervaloNotificaciones);
                this.idIntervaloNotificaciones = null;
              }
              this._router.navigate(['/login', '-1']);//Ocurre cuando la sesión es caducada o cerrada
            }

            this._servicioDatosGenerales.setFormularioBloqueado(this.bloqueado);
          } else {
            this.userIdle.stopWatching();
            if (this.idIntervaloNotificaciones) {
              clearInterval(this.idIntervaloNotificaciones);
              this.idIntervaloNotificaciones = null;
            }
            this._router.navigate(['/login', '-1']);
          }
        },
        error => {//Al salir un error al verificar estado de la sesión se cierra el sistema para impedir movimientos no adecuados
          if (this.idIntervaloNotificaciones) {
            clearInterval(this.idIntervaloNotificaciones);
            this.idIntervaloNotificaciones = null;
          }
          this.userIdle.stopWatching();
          this._router.navigate(['/login', '-1']);
        }
      )
  }

  // //Función que pinta los mensajes de los componentes hijos en éste que es el principal
  // obtenerMensajeComponentes(evento) {
  //     this.mostrarMensaje('E',evento.mensaje);
  // }

  //Vetrifica si existe un registro igual en la lista de tabs
  existeTabEnLista(datosMenu) {
    for (let i = 0; i < this.listaTabs.length; i++) {
      if (this.listaTabs[i]['id'] == datosMenu.id) {
        return i;
      }
    }
    return -1;
  }

  //Función para genenrar un nuevo formulario al dar click en el menu seleccionado
  generarTabIdSeleccionado(datosMenu: any) {
    let pos: number;
    if (this.listaTabs.length < 10) {//Solo pueden haber diez tabs abiertos
      pos = this.existeTabEnLista(datosMenu);
      if (pos == -1) {
        let tab = new Tab(datosMenu);
        this.listaTabs.push(tab);//Adiciono el elemento a la lista
        this.indice = this.listaTabs.length - 1;
      } else {
        this.indice = pos;
      }
    } else {//Si ya hay diez tabs
      pos = this.existeTabEnLista(datosMenu);
      if (pos != -1) {//Si existe, lo marco
        this.indice = pos;
      } else {
        this.mostrarMensaje('A', 'Demasiados formularios abiertos, considere cerrar alguno que no esté utilizando.');
      }
    }
  }

  cerrarTab(event: any) {
    if (event.index == this.indice) {
      this.indice = 0;
    } else if (event.index < this.indice) { // Esto se hace ya que al eliminar un tab se quedaría perdido cual es el tab activo
      this.indice = this.indice - 1;
    }
    let idMenu = this.listaTabs[event.index].id;
    this._servicioDatosGenerales.eliminarProcesoListaMenu(idMenu);
    this.listaTabs.splice(event.index, 1);//ELimino el elemento del array
    event.close();
  }

  obtenerMensaje(event: any) {
    this.mostrarMensaje(event.tipo, event.mensaje);
  }

  mostrarMensaje(tipo: string, mensaje) {
    this._servicioDatosGenerales.enviarMensaje(tipo, mensaje);
  }

  actualizarIndiceTab(event) {
    this.indice = event.index;
  }

  //Función que actualiza la ruta de los tabs para que puedan ser visualizados en sus tooltips
  tomarRutaFormulario(informacionMenu) {
    for (let i = 0; i < this.listaTabs.length; i++) {
      if (this.listaTabs[i].id == informacionMenu.id) {
        this.listaTabs[i].ruta = informacionMenu.ruta;
        i = this.listaTabs.length;
      }
    }
  }

  //Cambia el estado de la ayuda tanto en la variable de clase como en la variable global
  cambiarEstadoAyuda(activa: boolean) {
    this.ayudaActiva = activa;
    this._servicioDatosGenerales.setAyuda(activa);
    if (activa) {
      this.items[0].label = 'Desactivar ayudas visuales';
    } else {
      this.items[0].label = 'Activar ayudas visuales';
    }
  }

  tomarMensajeComponente(datos: any) {
    this.mostrarMensaje(datos.tipo, datos.mensaje);
  }

  //Utilizado desde el formulario de bloqueo. Duplicado, Unificar en una clase general
  solicitarLlaveLogin(event: string) {
    this.mensaje = '';
    let pass: string = event;
    let llamado = { "accion": "inicio" }
    this.loading = true;
    this._servicioPeticiones.enviarObjetoBus(llamado)
      .subscribe(//suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
        response => {
          let respuesta = response[0];//Obtengo la respuesta del api rest
          if (respuesta.valores) {
            if (respuesta.valores.resultado == 0) {//Conexion sin inconvenientes
              this.codigoAcceso = respuesta.valores.codigo_inicial_acceso;//Obtengo la llave privada
              this.idAcceso = respuesta.valores.id;
              this.activarSesion(pass);
            } else {
              this.mensaje = respuesta.valores.mensaje;
              this.loading = false;
            }
          } else {
            this.mensaje = 'Ocurrió un error inesperado, por favor contácte al administrador del sistema';

          }
        },
        error => {
          this.loading = false;
          this.mensaje = "No se puede tener conexión, por favor recargue el sistema, si el error persiste contáctese con el administrador.";

        })
  }

  activarSesion(pass: string) {
    this.loading = true;
    let llamado = { "accion": "activar", parametros: { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "id": this.idAcceso, "password": this._funcionesSesion.encriptarRsa(pass, this.codigoAcceso) } };
    this._servicioPeticiones.enviarObjetoBus(llamado)
      .subscribe(//suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
        response => { //si la peticion fue exitosa ejecuta la funcion response
          let respuesta = response[0];//Obtengo la respuesta del api rest
          if (respuesta && respuesta.valores) {
            //    this.bloqueado = false;
            if (respuesta.valores.resultado == 0) {
              this._servicioDatosGenerales.limpiarMensajes();
              this.bloqueado = false;
              this.userIdle.startWatching();
              this._servicioDatosGenerales.cambiarCodigoIdentity(respuesta.valores.codigoSesion);
              if (!this.idIntervaloNotificaciones) {
                this.consultarNotificacionesUsuario();
                this.idIntervaloNotificaciones = setInterval(() => { this.consultarNotificacionesUsuario(); console.log('Intervalo Notificaciones:' + this.idIntervaloNotificaciones + new Date()); }, 300000); // 5 Minutos
              }
            } else {
              this.bloqueado = true;
              this.mensaje = respuesta.valores.mensaje;//Se mantiene la variable de mensaje para que se muestre en el formulario de bloqueo la respuesta
            }
            this._servicioDatosGenerales.setFormularioBloqueado(this.bloqueado);
          } else {
            this.mensaje = 'Ocurrió un error inesperado, por favor contáctese con el administrador del sistema';
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.mensaje = "No se puede tener conexión, por favor recargue el sistema, si el error persiste contáctese con el administrador.";
        }
      )
  }

  //Función que enruta al login enviando un parámetro que indica que se esta cerrando sesión
  cerrarSesion(tipo: string) {
    if (this.idIntervaloNotificaciones) {
      clearInterval(this.idIntervaloNotificaciones);
      this.idIntervaloNotificaciones = null;
    }
    this.userIdle.stopWatching();
    this._servicioDatosGenerales.limpiarMensajes();
    this._router.navigate(['/login', tipo]);
  }


}
